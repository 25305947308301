<template>
  <div class='relative'
    :class='conferenceBannerClass' >
    <content-background-cover
      :background-color='backgroundColor'
      :custom-css='customCssBackground'
      :top-offset='0'
      class='z-0' />
    <vue-slick-carousel v-if='eventConfigWelcomeImages.length' v-bind='carouselSettings'>
      <div v-for='(welcomeImage, index) in eventConfigWelcomeImages' :key='`welcomeImage-${index}`'>
        <picture
          :class='hasLinkCssClass(welcomeImage.siteUrl)'
          :style='customImageCss'
          @click='clickImage(welcomeImage.siteUrl)'>
          <source media='(max-width: 552px)' :srcset='welcomeImage.mobileImageUrl'>
          <img :src='welcomeImage.welcomeImageUrl'>
        </picture>
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import { mapGetters }         from 'vuex'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import VueSlickCarousel       from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'WelcomeConferenceBanner',
  props: {
    backgroundColor: {
      type: String,
      default: '#F9F6F9',
    },
    customCssBackground: {
      type: String,
      default: '',
    },
    customImageCss: {
      type: String,
      default: '',
    },
    showScrollArrows: {
      type: Boolean,
      default: true,
    },
    showDots: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VueSlickCarousel,
    ContentBackgroundCover,
  },
  computed: {
    ...mapGetters('events', [
      'eventConfigWelcomeImages',
    ]),
    conferenceBannerClass () {
      return this.eventConfigWelcomeImages.length > 1 ? 'mx-8' : ''
    },
    carouselSettings () {
      return {
        'lazyLoad': 'ondemand',
        'arrows': this.showScrollArrows,
        'dots': this.showDots,
        'dotsClass': 'slick-dots custom-dot-class',
        'focusOnSelect': true,
        'infinite': true,
        'speed': 500,
        'autoplay': true,
        'autoplaySpeed': 10000,
        'slidesToShow': 1,
        'slidesToScroll': 1,
      }
    },
  },
  methods: {
    clickImage (url) {
      if (url) {
        if (url.indexOf('http') != -1) {
          window.open(url, '_blank')
        } else {
          window.open(`https://${url}`, '_blank')
        }
      } else {
        // do nothing
      }
    },
    hasLinkCssClass (url) {
      return (url) ? 'cursor-pointer' : ''
    },
  }
}
</script>
