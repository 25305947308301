<template>
  <div>
    <welcome-conference-banner class='py-8' background-color='#FAFAFA'/>
    <div class='pt-8 px-2'>
      <button
        class='lg:hidden w-full py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
        :style="`background-color: ${eventMainThemeColor};`"
        @click='goToCurrentSession'>
        입장하기
      </button >
    </div>
    <div class='relative pt-8 overflow-x-auto cursor-pointer' :style='setupVariableColors'
      @click='goToCurrentSession'>
      <table class='w-full text-gray-900 text-xs lg:text-sm'>
        <thead>
          <tr>
            <th class='border-b px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>시간</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left'>프로그램</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
            <th class='border-b px-2 py-2 text-left w-1/3 lg:w-auto'>좌장 및 연자</th>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>08:50 - 09:00</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>Opening Remarks</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'>이호승 <span class='block lg:inline-block lg:ml-1 opacity-70'>(울산대학교 서울아산병원, 대한정형외과학회 진단 및 장애위원회 위원장)</span></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:00 - 10:50</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Session I</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이순혁 <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울적십자병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:00 - 09:15</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>1. 전공의들이 알아야 할 장애 평가의 기초</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이순혁 <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울적십자병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='table-row lg:hidden font-medium'>
            <td class='border-b px-2 py-2 text-center' colspan='3'>&#60;부위별 장애 판정 사례&#62;</td>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:15 - 09:30</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell' rowspan='7'>부위별 장애 판정 사례</td>
            <td class='border-b px-2 py-2'>2. 견주관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>조남수 <span class='block lg:inline-block lg:ml-1 opacity-70'>(제일정형외과병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:30 - 09:45</td>
            <td class='border-b px-2 py-2'>3. 수부</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이재성 <span class='block lg:inline-block lg:ml-1 opacity-70'>(중앙대학교병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:45 - 09:55</td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>09:55 - 10:10</td>
            <td class='border-b px-2 py-2'>4. 골반골 및 고관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이영균 <span class='block lg:inline-block lg:ml-1 opacity-70'>(분당서울대학교병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>10:10 - 10:25</td>
            <td class='border-b px-2 py-2'>5. 슬관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이상학 <span class='block lg:inline-block lg:ml-1 opacity-70'>(강동경희대학교병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>10:25 - 10:40</td>
            <td class='border-b px-2 py-2'>6. 척추</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>안동기 <span class='block lg:inline-block lg:ml-1 opacity-70'>(서울성심병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>10:40 - 10:50</td>
            <td class='border-b px-2 py-2'>질의 및 응답</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='table-row lg:hidden font-medium'>
            <td class='border-b px-2 py-2 text-center' colspan='3'>&#60;산학심포지엄 - 대원제약&#62;</td>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>10:50 - 11:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>산학심포지엄 - 대원제약</td>
            <td class='border-b px-2 py-2'>Medical Treatment of OA; Combined Therapy</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>최윤락 <span class='block lg:inline-block lg:ml-1 opacity-70'>(연세대학교 세브란스병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='font-semibold bg-gray-200'>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>11:10 - 11:20</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>휴식</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b border-gray-600 px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t border-gray-600 font-semibold' style='background-color:var(--lightColor);'>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>11:20 - 12:40</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2'>Session II</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>이호승 <span class='block lg:inline-block lg:ml-1 opacity-70'>(울산대학교 서울아산병원, 대한정형외과학회 진단 및 장애위원회 위원장)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'>
              <button
                class='py-1 px-4 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200'
                :style="`background-color: ${eventMainThemeColor};`"
                @click='goToCurrentSession'>
                입장하기
              </button>
            </th>
          </tr>
          <tr class='table-row lg:hidden font-medium'>
            <td class='border-b px-2 py-2 text-center' colspan='3'> &#60;부위별 장애 판정 사례&#62; </td>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>11:20 - 11:35</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell' rowspan='2'>부위별 장애 판정 사례</td>
            <td class='border-b px-2 py-2'>7. 말초 신경</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>김병성 <span class='block lg:inline-block lg:ml-1 opacity-70'>(순천향대학교 부천병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>11:35 - 11:50</td>
            <td class='border-b px-2 py-2'>8. 족부족관절</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>15분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>박세진 <span class='block lg:inline-block lg:ml-1 opacity-70'>(성균관대학교 강북삼성병원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr class='table-row lg:hidden font-medium'>
            <td class='border-b px-2 py-2 text-center' colspan='3'>&#60;법원 및 손보사의 장애 판정에 관한 입장&#62;</td>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>11:50 - 12:10</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell' rowspan='2'>법원 및 손보사의<br>장애 판정에 관한 입장</td>
            <td class='border-b px-2 py-2'>9. 법원에서 본 정형외과 신체감정의 흔한 문제들</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>김동원 <span class='block lg:inline-block lg:ml-1 opacity-70'>(대구고등법원 상임전문심리위원)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
          <tr>
            <td class='border-b px-2 py-2 text-left lg:text-center  w-1/6 lg:w-auto'>12:10 - 12:30</td>
            <td class='border-b px-2 py-2'>10. 무심히 발부한 장애진단서 이후의 결과는?</td>
            <td class='border-b px-2 py-2 hidden lg:table-cell'>20분</td>
            <td class='border-b px-2 py-2 w-1/3 lg:w-auto'>임재욱 <span class='block lg:inline-block lg:ml-1 opacity-70'>(엘의료 분석원 대표이사)</span></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
        <tbody>
          <tr class='bg-gray-50 opacity-60'>
            <td class='border-b border-gray-600 px-2 py-2 text-left lg:text-center w-1/6 lg:w-auto'>12:30 - 12:40</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'></td>
            <td class='border-b border-gray-600 px-2 py-2'>질의 및 응답</td>
            <td class='border-b border-gray-600 px-2 py-2 hidden lg:table-cell'>10분</td>
            <td class='border-b border-gray-600 px-2 py-2 w-1/3 lg:w-auto'></td>
            <th class='border-b px-2 py-2 text-left hidden lg:table-cell'></th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'

export default {
  name: 'KoaCustomProgram',
  components: {
    WelcomeConferenceBanner,
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'colorsInRange',
    ]),
    setupVariableColors () {
      return {
       '--lightestColor': this.colorsInRange[0],
       '--lightColor': this.colorsInRange[1],
       '--inputColor': this.colorsInRange[2],
       '--darkColor': this.colorsInRange[3], 
      }
    },
  },
  methods: {
    goToCurrentSession () {
      this.$router.push({name: 'CurrentSession'}).catch(() => {})
    }
  }
}
</script>
